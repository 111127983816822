<style scoped>
.c-panel {
  margin: 0px;
  padding: 0px 20px;
}
</style>

<template>
  <el-dialog v-if="m" :title="this.id == 0 ? '数据增加' : '数据修改'" :visible.sync="isShow" width="650px" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog" :show-close="false">
    <div class="vue-box">
      <div class="c-panel">
        <br />
        <el-form size="small" label-width="150px">
          <el-form-item label="微信商户名:">
            <el-input v-model="m.mchName" style="width: 350px"></el-input>
          </el-form-item>
          <el-form-item v-if="m.mode != 2" label="商户号:">
            <el-input v-model="m.wechatMch" style="width: 350px"></el-input>
          </el-form-item>
          <el-form-item label="限额:">
            <el-input v-model="m.limits" style="width: 350px"></el-input>
          </el-form-item>
          <el-form-item label="是否启用:">
            <el-select v-model="m.status">
              <el-option label="开启" :value="1"></el-option>
              <el-option label="关闭" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商户模式:">
            <el-select v-model="m.mode" @click.native="m.providerMch = ''">
              <el-option label="单商户模式" :value="0"></el-option>
              <el-option label="电商收付通模式" :value="1"></el-option>
              <el-option label="微信收款单" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="m.mode == 1" label="服务商号:">
            <el-select v-model="m.providerMch" @click.native="getProvider()">
              <el-option v-for="item in providerselectList" :key="item.providerMch" :label="item.providerName" :value="item.providerId">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item v-if="m.mode == 0" label="微信公众号:">
            <el-select v-model="m.providerMch" @click.native="getOfficailAccount()">
              <el-option v-for="item in officailselectList" :key="item.officailAppid" :label="item.officailName" :value="item.officailAppid">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item v-if="m.mode == 0" label="证书序列号:">
            <el-input v-model="m.serialNumber" style="width: 350px"></el-input>
          </el-form-item>

          <el-form-item v-if="m.mode == 0" label="v3key:">
            <el-input v-model="m.v3Key" style="width: 350px"></el-input>
          </el-form-item>

          <el-form-item v-if="m.mode == 0" label="v2key:(如需使用付款码支付必填)">
            <el-input v-model="m.v2Key" style="width: 350px"></el-input>
          </el-form-item>

          <el-form-item v-if="m.mode == 0" label="私钥文本:">
            <el-input :rows="5" style="width: 350px" type="textarea" v-model="m.privateKey"></el-input>
          </el-form-item>

          <el-form-item v-if="m.mode == 2" label="收款单ID:">
            <el-input :rows="5" style="width: 350px" v-model="m.accountId"></el-input>
          </el-form-item>

          <el-form-item v-if="m.mode == 2" label="收款单类型:">
            <el-select v-model="m.accountType">
              <el-option label="商户" :value="1"></el-option>
              <el-option label="个人" :value="2"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item v-if="m.mode == 2" label="店铺ID:">
            <el-input :rows="5" style="width: 350px" v-model="m.shopId"></el-input>
          </el-form-item>

          <el-form-item v-if="m.mode == 2" label="sid(会话id):">
            <el-input :rows="5" style="width: 350px" v-model="m.sid"></el-input>
          </el-form-item>

          <el-form-item label="备注:">
            <el-input style="width: 350px" type="textarea" :rows="7" v-model="m.remarks">
            </el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="(isShow = false), claerData()">取 消</el-button>
      <el-button size="small" type="primary" @click="ok()">操 作</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      id: 0,
      m: {
        wechatMch: "", // 从菜单配置文件里传递过来的参数
        mchName: "",
        mode: 0,
        limits: 5000,
        status: 0,
        remarks: "",
        providerMch: "",
        serialNumber: "",
        v3Key: "",
        v2Key: "",
        privateKey: "",
        accountId: "",
        accountType: "",
        shopId: "",
        sid: ""
      },
      providerselectList: [],
      officailselectList: [],
      p: {
        // 查询参数
        type: 1,
        page: 1,
        limit: 20,
      },
    };
  },
  methods: {
    // 打开
    open: function (data) {
      this.id = data.id;
      this.isShow = true;
      if (this.id == 0 || data == 0) {
        // 如果是添加
        this.id = 0;
      } else {
        this.m.mode = data.mode;
        this.m.wechatMch = data.wechatMch;
        this.m.wechatMch = data.wechatMch;
        this.m.providerMch = data.providerMch;
        this.m.remarks = data.remarks;
        this.m.limits = data.limits;
        this.m.status = data.status;
        this.m.mchName = data.mchName;
        this.m.serialNumber = data.serialNumber;
        this.m.v3Key = data.v3Key;
        this.m.v2Key = data.v2Key;
        this.m.privateKey = data.privateKey;
        this.m.accountId = data.accountId;
        this.m.accountType = data.accountType;
        this.m.shopId = data.shopId;
        this.m.sid = data.sid;
      }
    },
    getProvider: function () {
      var defaultCfg = {
        type: "get", // 默认请求类型
        msg: null,
      };
      this.sa.ajax(
        this.sa.cfg.provider_api_url + "/provider/list",
        this.p,
        function (res) {
          this.providerselectList = res.data.records; // 数据
        }.bind(this),
        defaultCfg
      );
    },
    getOfficailAccount: function () {
      var defaultCfg = {
        type: "post", // 默认请求类型
        isBody: true, //是否是请求体请求
        msg: null,
      };
      if (this.sa_admin.role == 2) {
        this.sa.ajax(
          "/v1/officail/list",
          this.p,
          function (res) {
            this.officailselectList = res.data.records; // 数据
          }.bind(this),
          defaultCfg
        );
      } else {
        this.p.mchId = this.sa_admin.mchId;
        this.sa.ajax(
          "/v1/officail/list",
          this.p,
          function (res) {
            this.officailselectList = res.data.records; // 数据
          }.bind(this),
          defaultCfg
        );
      }
    },
    // 表单验证
    submit_check: function () {
      return "ok";
    },
    // 提交
    ok: function () {
      // 表单验证
      if (this.submit_check() != "ok") {
        return;
      }
      // 开始增加或修改
      let self = this;
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      if (this.id == 0) {
        // id == 0 为增加
        this.sa.ajax(
          "/v1/wechat/mch/save",
          this.m,
          function () {
            self.sa.alert("增加成功", function () {
              self.$parent.f5(); // 父视图刷新
              self.isShow = false;
              self.claerData();
            });
          },
          defaultCfg
        );
      } else {
        // id != 0 为修改
        this.sa.ajax(
          "/v1/wechat/mch/edit/" + this.id,
          this.m,
          function () {
            self.sa.alert(
              "修改成功",
              function () {
                self.$parent.f5(); // 父视图刷新
                self.isShow = false;
                self.claerData();
              },
              defaultCfg
            );
          },
          defaultCfg
        );
      }
    },
    claerData() {
      Object.keys(this.m).forEach((key) => {
        this.m[key] = "";
      });
    },
  },
  created: function () {
    this.getProvider()
    this.getOfficailAccount()
  },
};
</script>
