<style scoped>
.ck {
  cursor: pointer;
}
.el-switch.is-disabled {
  opacity: 1;
}
.el-switch.is-disabled .el-switch__core,
.el-switch.is-disabled .el-switch__label {
  cursor: pointer !important;
}
</style>
<template>
  <div class="vue-box">
    <div class="c-panel">
      <!-- 参数栏 -->
      <div class="c-title">微信商户列表</div>
      <el-form :inline="true" size="mini">
        <br />
        <el-form-item v-if="this.sa_admin.role == 2" label="码队选择:">
          <el-select size="mini" v-model="p.mchId" @click.native="getMchCode" @change="f5">
            <el-option label="全部" :value="null"></el-option>
            <el-option v-for="item in selectList" :key="item.mchId" :label="item.merchantNickName" :value="item.mchId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="微信商户号:">
          <el-input v-model="p.wechatMch" placeholder="微信商户号" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态筛选:">
          <el-radio v-model="p.status" label="3">全部</el-radio>
          <el-radio v-model="p.status" label="1">开启</el-radio>
          <el-radio v-model="p.status" label="0">禁用</el-radio>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-if="this.sa_admin.role == 2" icon="el-icon-search" @click="
              p.page = 1;
              f5();
            ">查询</el-button>
          <el-button type="primary" v-if="this.sa_admin.role == 3" icon="el-icon-plus" @click="add()">添加微信商户</el-button>
          <el-button type="success" icon="el-icon-refresh" @click="
              p.page = 1;
              f5();
            ">刷新</el-button>
        </el-form-item>
      </el-form>
      <el-table :row-class-name="tableRowClassName" :data="dataList" :key="conditionsKey" :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }" size="mini">
        <el-table-column label="编号" prop="id" width="50px"> </el-table-column>
        <el-table-column label="微信商户名" prop="mchName" width="150px"></el-table-column>
        <el-table-column label="微信商户号" prop="wechatMch" width="100px"></el-table-column>
        <!-- <el-table-column label="服务商号" width="120px">
          <template slot-scope="s">
            <el-tag size="danger">{{ s.row.providerMch }}</el-tag>
          </template>
        </el-table-column> -->
        <el-table-column label="创建时间" prop="createdTime" width="100px">
        </el-table-column>
        <el-table-column label="状态" width="120px">
          <template slot-scope="s">
            <el-switch v-model="s.row.status" disabled :active-value="1" :inactive-value="0" @click.native="setStatus(s.row)"></el-switch>
            <b style="color: green; margin-left: 10px" v-if="s.row.status == 1">启用</b>
            <b style="color: red; margin-left: 10px" v-if="s.row.status == 0">禁用</b>
          </template>
        </el-table-column>
        <el-table-column label="商户模式" width="120px">
          <template slot-scope="s">
            <b style="margin-left: 10px" v-if="s.row.mode == 0">单商户模式</b>
            <b style="margin-left: 10px" v-if="s.row.mode == 1">电商收付通模式</b>
            <b style="margin-left: 10px" v-if="s.row.mode == 2">收款单模式</b>
          </template>
        </el-table-column>
        <el-table-column label="昨日收入" width="170px">
          <template slot-scope="s">
            <el-link style="color: green; margin-right: 5px; font-size: 14px">{{
              s.row.zrsr
            }}</el-link>
            <el-link icon="el-icon-refresh" style="font-size: 16px" @click="yesterDayRefreshFee(s.row)"></el-link>
          </template>
        </el-table-column>
        <el-table-column label="今日收入" width="170px">
          <template slot-scope="s">
            <el-link style="color: green; margin-right: 5px; font-size: 14px">{{
              s.row.jrsr
            }}</el-link>
            <el-link icon="el-icon-refresh" style="font-size: 16px" @click="toDayRefreshFee(s.row)"></el-link>
          </template>
        </el-table-column>
        <el-table-column label="今日成功率" width="100px">
          <template slot-scope="s">
            <el-link style="color: green; margin-right: 5px; font-size: 14px">{{
              s.row.jrcgl
            }}</el-link>
            <el-link icon="el-icon-refresh" style="font-size: 16px" @click="toDayRefreshRate(s.row)"></el-link>
          </template>
        </el-table-column>
        <el-table-column label="限额" min-width="150px">
          <template slot-scope="s">
            <b>￥{{ s.row.limits }}</b>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="备注" prop="remarks" :formatter="remarksFormat">
        </el-table-column>
        <el-table-column prop="address" label="操作" width="150px" fixed="right">
          <template slot-scope="s">
            <el-button class="c-btn" type="success" icon="el-icon-edit" @click="update(s.row)">编辑</el-button>
            <el-dropdown style="margin-left: 5px;">
              <span class="el-dropdown-link">
                <el-button class="c-btn" type="primary" icon="el-icon-more">更多</el-button>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-link" @click.native="appIOS(s.row)">appIOS</el-dropdown-item>
                <el-dropdown-item icon="el-icon-link" @click.native="yc(s.row)">扫码内付</el-dropdown-item>
                <el-dropdown-item icon="el-icon-link" @click.native="h5yc(s.row)">合单H5</el-dropdown-item>
                <el-dropdown-item icon="el-icon-link" @click.native="tiaoma(s.row)">条码</el-dropdown-item>
                <el-dropdown-item icon="el-icon-delete" @click.native="del(s.row)">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="page-box">
        <el-pagination background layout="total, prev, pager, next, sizes" :current-page.sync="p.page" :page-size.sync="p.limit" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]" @current-change="f5(true)" @size-change="f5(true)">
        </el-pagination>
      </div>
    </div>
    <AddCodeMch ref="add-code-update"></AddCodeMch>
    <WeChatPayMicroPay ref="we-chat-pay"></WeChatPayMicroPay>
  </div>
</template>

<script>
import AddCodeMch from "./add-code-mch.vue";
import WeChatPayMicroPay from "./weChatPayMicroPay.vue";
export default {
  components: { AddCodeMch, WeChatPayMicroPay },
  data() {
    return {
      p: {
        // 查询参数
        mchName: null,
        mchId: null,
        status: "3",
        page: 1,
        limit: 10,
      },
      title: "添加微信商户",
      userDialogVisible: false,
      dataCount: 0,
      dataList: [],
      selectList: [],
      conditionsKey: 0,
    };
  },
  methods: {
    // 数据刷新
    f5: function () {
      if (this.sa_admin.role == 2) {
        var defaultCfg = {
          isBody: true, //是否是请求体请求
        };
        this.sa.ajax(
          "/v2/admin/queryAllWechatMchInfo",
          this.p,
          function (res) {
            this.dataList = res.data.records; // 数据
            this.dataCount = res.data.total; // 分页
          }.bind(this),
          defaultCfg
        );
      } else {
        this.p.mchId = this.sa_admin.mchId;
        defaultCfg = {
          isBody: true, //是否是请求体请求
        };
        this.sa.ajax(
          "/v2/admin/queryAllWechatMchInfo",
          this.p,
          function (res) {
            this.dataList = res.data.records; // 数据
            this.dataCount = res.data.total; // 分页
          }.bind(this),
          defaultCfg
        );
      }
    },
    tiaoma: function (data) {
      this.$refs["we-chat-pay"].open(data);
    },
    appIOS: function (data) {
      let self = this;
      this.sa.prompt(
        "输入创建订单的金额",
        function (value) {
          var p1 = {
            subMchid: data.wechatMch,
            price: value,
            //固定订单数量
            num: 3,
            codeMchId: data.mchId,
          };
          var defaultCfg = {
            type: "get",
          };
          if (data.mode == 1) {
            self.sa.ajax(
              "/v2/admin/precharge_app",
              p1,
              function (res) {
                self.sa.showImageWithText(
                  this.sa.cfg.api_url +
                  "/v1/index/enQrcode?url=" +
                  encodeURIComponent(res.data),
                  "600px",
                  "300px", res.data
                );
              }.bind(self),
              defaultCfg
            );
          } else {
            self.sa.error("单商户模式不支持AppIOS单端")
          }
        },
        0
      );
    },
    toDayRefreshRate: function (data) {
      //获取金额
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      var p2 = {
        wechatMch: data.wechatMch, //是否是请求体请求
      };
      this.sa.ajax(
        "/v1/dataAnalysis/dataAnalysisBySubMchIdToDay",
        p2,
        function (res) {
          res.data = parseFloat(res.data).toFixed(2);
          data.jrcgl = res.data + "%";
          this.$set(this.dataList, data.index, data);
          this.conditionsKey++;
        }.bind(this),
        defaultCfg
      );
    },
    h5yc: function (data) {
      let self = this;
      this.sa.prompt(
        "输入创建订单的金额",
        function (value) {
          var p1 = {
            subMchid: data.wechatMch,
            price: value,
            //固定订单数量
            num: 3,
            codeMchId: data.mchId,
          };
          var defaultCfg = {
            type: "get",
          };
          if (data.mode == 1) {
            self.sa.ajax(
              "/v2/admin/combineOrder",
              p1,
              function (res) {
                self.sa.showImageWithText(
                  this.sa.cfg.api_url +
                  "/v1/index/enQrcode?url=" +
                  encodeURIComponent(res.data),
                  "600px",
                  "300px", res.data
                );
              }.bind(self),
              defaultCfg
            );
          } else {
            self.sa.error("单商户模式不支持H5预充")
          }
        },
        0
      );
    },
    yc: function (data) {
      let self = this;
      this.sa.prompt(
        "输入创建订单的金额",
        function (value) {
          var p1 = {
            subMchid: data.wechatMch,
            price: value,
            //固定订单数量
            num: 3,
            codeMchId: data.mchId,
          };
          var defaultCfg = {
            type: "get",
          };
          if (data.mode == 1) {
            self.sa.ajax(
              "/v2/admin/precharge_jsapi",
              p1,
              function (res) {
                self.sa.showImage(
                  this.sa.cfg.api_url +
                  "/v1/index/enQrcode?url=" +
                  encodeURIComponent(res.data),
                  "300px",
                  "300px"
                );
              }.bind(self),
              defaultCfg
            );
          } else {
            self.sa.ajax(
              "/v2/admin/preNative",
              p1,
              function (res) {
                self.sa.showImage(
                  this.sa.cfg.api_url +
                  "/v1/index/enQrcode?url=" +
                  encodeURIComponent(res.data),
                  "300px",
                  "300px"
                );
              }.bind(self),
              defaultCfg
            );
          }
        },
        0
      );
    },
    getMchCode: function () {
      var defaultCfg = {
        type: "get", //是否是请求体请求
        sleep: 0,
        msg: null,
      };
      this.sa.ajax(
        "/v2/admin/queryMerchantNickName/3",
        function (res) {
          this.selectList = res.data; // 数据
        }.bind(this),
        defaultCfg
      );
    },

    del: function (row) {
      var defaultCfg = {
        type: "delete",
      };
      this.sa.ajax(
        "/v1/wechat/mch/delete/" + row.id,
        function () {
          this.sa.ok("删除成功");
          this.f5();
        }.bind(this),
        defaultCfg
      );
    },
    // 查看
    yesterDayRefreshFee: function (data) {
      //获取金额
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      var p2 = {
        wechatMch: data.wechatMch, //是否是请求体请求
      };
      if (data.mode == 2) {
        p2 = {
          wechatMch: data.accountId, //是否是请求体请求
        };
      }
      this.sa.ajax(
        "/v1/dataAnalysis/realtimeAmountYesterday",
        p2,
        function (res) {
          res.data = parseFloat(res.data).toFixed(2);
          data.zrsr = "￥" + res.data;
          this.$set(this.dataList, data.index, data);
          this.conditionsKey++;
        }.bind(this),
        defaultCfg
      );
    },
    toDayRefreshFee: function (data) {
      //获取金额
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };

      var p2 = {
        wechatMch: data.wechatMch, //是否是请求体请求
      };
      if (data.mode == 2) {
        p2 = {
          wechatMch: data.accountId, //是否是请求体请求
        };
      }

      this.sa.ajax(
        "/v1/dataAnalysis/realtimeAmountToDay",
        p2,
        function (res) {
          res.data = parseFloat(res.data).toFixed(2);
          data.jrsr = "￥" + res.data;
          this.$set(this.dataList, data.index, data);
          this.conditionsKey++;
        }.bind(this),
        defaultCfg
      );
    },
    /** 展示用户列表页面 **/
    add: function () {
      this.$refs["add-code-update"].open(0);
    },
    update: function (data) {
      this.$refs["add-code-update"].open(data);
    },
    setStatus: function (data) {
      var defaultCfg = {
        type: "get", //是否是请求体请求
      };
      var params = data;
      this.sa.ajax(
        "/v1/wechat/mch/setStatus/" + data.id,
        function () {
          this.sa.ok("修改成功");
          params.status = params.status == 1 ? 0 : 1;
        }.bind(this),
        defaultCfg
      );
    },
    remarksFormat(row) {
      if (row.remarks == "" || row.remarks == null) {
        return "无备注";
      } else {
        return row.remarks;
      }
    },
    tableRowClassName({ row, rowIndex }) {
      //把每一行的索引放进row
      row.index = rowIndex;
    },
    loadmore() {
    },
  },
  created: function () {
    this.f5();
    this.getMchCode();
  },
};
</script>

<style>
</style>
