<style scoped>
.c-panel {
  margin: 0px;
  padding: 0px 20px;
}
</style>

<template>
  <el-dialog v-if="m" title="付款码支付" :visible.sync="isShow" width="650px" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog" :show-close="false">
    <div class="vue-box">
      <div class="c-panel">
        <br />
        <el-form size="small" :inline="true" label-width="150px">
          <el-form-item label="付款码:">
            <el-input v-model="m.authCode" style="width: 350px"></el-input>
          </el-form-item>
          <br />
          <el-form-item label="金额:">
            <el-input v-model="m.totalFee" style="width: 350px"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="(isShow = false), claerData()">取 消</el-button>
      <el-button size="small" type="primary" @click="ok()">支付</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      id: 0,
      mode: 0,
      timer: null,
      orderId: 0,
      m: {
        authCode: "", // 从菜单配置文件里传递过来的参数
        totalFee: "",
        wechatMch: "",
        codeMchId: ""
      },
    };
  },
  methods: {
    // 打开
    open: function (data) {
      this.id = data.id;
      this.isShow = true;
      this.mode = data.mode;
      this.m.wechatMch = data.wechatMch;
      console.log(data);
    },
    // 表单验证
    submit_check: function () {
      return "ok";
    },
    // 提交
    ok: function () {
      // 表单验证
      if (this.submit_check() != "ok") {
        return;
      }
      // 开始增加或修改
      let self = this;
      console.log(this.mode);
      this.m.codeMchId = this.sa_admin.mchId;
      if (this.mode == 0) {
        self.sa.ajax(
          "/v2/admin/weChatPayMicroPay",
          this.m,
          function (res) {
            console.log(res);
            self.orderId = res.data
            self.timer = setInterval(this.orderQuery, 5000);
            self.sa.loading("正在等待用户付款");
          }.bind(self)
        );
      } else {
        self.sa.error("收付通模式暂不支持付款码支付")
      }
    },
    claerData() {
      this.m.authCode = "";
      this.m.totalFee = "";
      this.m.wechatMch = "";
      this.m.codeMchId = "";
      clearInterval(this.timer);
      this.timer = null;
    },
    orderQuery() {
      let self = this;
      self.sa.ajax(
        "/v2/admin/weChatPayMicroPayOrderQuery",
        { orderId: this.orderId },
        function (res) {
          console.log(res);
          if (res.data == "支付完成") {
            self.sa.ok2("支付成功")
            clearInterval(this.timer);
            self.timer = null;
            self.isShow = false;
            self.claerData();
          } else {
            // this.sa.ok("正在等待用户付款");
            self.sa.loading("正在等待用户付款");
          }
        }.bind(self)
      );
    },
  },
  created: function () {
  },
};
</script>
